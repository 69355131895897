import React, { FC, HTMLAttributes } from "react";
import { TourCardFragment } from "@graphql/types";
import { Trans, t } from "@helpers/translate";
import { getDuration } from "@helpers/getDuration";
import { getCurrencyConversion } from "@helpers/getCurrencyConversion";
import { useRouter } from "next/router";
import Link from "next/link";
import { Card } from "@components/ui/Card";
import { Avatar } from "@components/ui/Avatar";
import { Rating } from "@components/ui/Rating";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { Image } from "@components/ui/Image";
import { twMerge } from "tailwind-merge";

type IProps = {
  showUser?: boolean;
  onRatingClicked?: () => void;
  ratingVariant?: "default" | "stars";
  tour: TourCardFragment;
  variant: "horizontal" | "vertical";
  className?: HTMLAttributes<HTMLDivElement>["className"];
};

const TourCard: FC<IProps> = ({
  showUser,
  onRatingClicked,
  ratingVariant = "default",
  tour,
  variant = "vertical",
  className,
}) => {
  const router = useRouter();
  const { query } = router;

  let tourUrl = tour.url;
  if (
    (query.t === undefined ||
      query.t === "tours" ||
      query.t === "transportation" ||
      query.t === "online-tours") &&
    (query.date !== "" || query.people !== "")
  ) {
    const queryParams = {
      ...(query.date !== undefined && { date: query.date }),
      ...(query.adults !== undefined && { adults: query.adults }),
      ...(query.children !== undefined && { children: query.children }),
      ...(query.infants !== undefined && { infants: query.infants }),
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryString = new URLSearchParams(queryParams as any).toString();
    tourUrl = queryString ? `${tourUrl}?${queryString}` : tourUrl;
  }

  const tourCoverImage =
    tour.picture?.url || tour.gallery?.find((_, i) => i === 0)?.src;

  if (variant === "vertical") {
    return (
      <Link
        href={getAbsoluteUrl(tourUrl)}
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
        target="_blank"
        className={twMerge("flex flex-1", className)}
      >
        <Card className="flex flex-col group mb-0">
          <div className="relative w-full h-48 bg-gray-200">
            {tourCoverImage && (
              <Image
                key={tour.id}
                src={tourCoverImage}
                alt={tour.title + "cover image"}
                fill={true}
                className="object-cover"
                activeCache={false}
              />
            )}
          </div>
          <Card.Container className="flex flex-1 flex-col">
            <Card.Body className="flex flex-col h-60">
              <Card.Title className="h-14 truncate-2 mb-3">
                {tour.title}
              </Card.Title>
              <Card.Tags>
                {tour.city && <Card.Tag>{tour.city.name}</Card.Tag>}
                {tour.duration && (
                  <Card.Tag>{getDuration(tour.duration)}</Card.Tag>
                )}
              </Card.Tags>
              <Card.Description className="truncate-3 h-16">
                {tour.overview || tour.content}
              </Card.Description>
              <Card.Price className="mb-4">
                <span className="text-gray-500">
                  <Trans id="tour.price.from">FROM</Trans>
                </span>
                <b
                  className="text-gray-800 text-lg mx-2"
                  suppressHydrationWarning
                >
                  {getCurrencyConversion(
                    tour.price.amount,
                    tour.price.currency
                  )}
                </b>
                <span className="text-gray-500">
                  {tour.price.model === "PERSON" && (
                    <Trans id="tour.price.per_person">/ per person</Trans>
                  )}
                  {tour.price.model === "GROUP" && (
                    <Trans id="tour.price.per_group">/ per group</Trans>
                  )}
                </span>
              </Card.Price>
            </Card.Body>
            {showUser && (
              <Card.Footer>
                <Avatar
                  src={tour.supplier.picture && tour.supplier.picture.url}
                  size="sm"
                  imgAlt={`${tour.country?.name} Tour Guide - ${tour.supplier.displayName}`}
                />
                <div className="ml-2">
                  <div className="text-blue-900 text-lg font-medium truncate w-44">
                    {tour.supplier.displayName}
                  </div>
                  {tour.supplier.stats.rating > 0 &&
                    tour.supplier.stats.reviewsCount > 0 && (
                      <Rating
                        rate={tour.supplier.stats.rating}
                        reviews={tour.supplier.stats.reviewsCount}
                        alwaysShowDecimals={true}
                        onClick={onRatingClicked}
                        size="sm"
                        variant={ratingVariant}
                      />
                    )}
                </div>
              </Card.Footer>
            )}
          </Card.Container>
        </Card>
      </Link>
    );
  }

  return (
    <Link
      href={getAbsoluteUrl(tourUrl)}
      title={t({
        id: "common.link.title",
        message: "GoWithGuide - Private Tours & Local Tour Guides",
      })}
      target="_blank"
      className={twMerge("flex flex-1 w-full", className)}
    >
      <Card className="flex flex-row mb-0 w-full">
        <div className="relative w-64 h-full bg-gray-200">
          {tourCoverImage && (
            <Image
              key={tour.id}
              src={tourCoverImage}
              alt={tour.title + "cover image"}
              className="w-full h-full object-cover"
              activeCache={false}
              fill={true}
            />
          )}
        </div>
        <Card.Container className="flex flex-col px-4 py-2 w-3/5">
          <Card.Body className="flex flex-col">
            <Card.Title className="truncate mb-3 w-72">{tour.title}</Card.Title>
            <Card.Tags>
              {tour.city && <Card.Tag>{tour.city.name}</Card.Tag>}
              {tour.duration && (
                <Card.Tag>{getDuration(tour.duration)}</Card.Tag>
              )}
            </Card.Tags>
            <Card.Description className="truncate-3 h-16">
              {tour.overview || tour.content}
            </Card.Description>
            <Card.Price>
              <span className="text-gray-500">
                <Trans id="tour.price.from">FROM</Trans>
              </span>
              <b
                className="text-gray-800 text-lg mx-2"
                suppressHydrationWarning
              >
                {getCurrencyConversion(tour.price.amount, tour.price.currency)}
              </b>
              <span className="text-gray-500">
                {tour.price.model === "PERSON" && (
                  <Trans id="tour.price.per_person">/ per person</Trans>
                )}
                {tour.price.model === "GROUP" && (
                  <Trans id="tour.price.per_group">/ per group</Trans>
                )}
              </span>
            </Card.Price>
          </Card.Body>
          {showUser && (
            <Card.Footer>
              <Avatar
                src={tour.supplier.picture && tour.supplier.picture.url}
                size="sm"
                imgAlt={`${tour.country?.name} Tour Guide - ${tour.supplier.displayName}`}
              />
              <div className="ml-2">
                <div className="text-blue-900 text-lg font-medium truncate w-44">
                  {tour.supplier.displayName}
                </div>
                {tour.supplier.stats.rating > 0 &&
                  tour.supplier.stats.reviewsCount > 0 && (
                    <Rating
                      rate={tour.supplier.stats.rating}
                      reviews={tour.supplier.stats.reviewsCount}
                      alwaysShowDecimals={true}
                      onClick={onRatingClicked}
                      size="sm"
                      variant={ratingVariant}
                    />
                  )}
              </div>
            </Card.Footer>
          )}
        </Card.Container>
      </Card>
    </Link>
  );
};

export default TourCard;
